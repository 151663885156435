@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

:root {
  --shadow: hsl(0 0% 0% / 0.9);
  --bg: hsl(0, 0%, 70%);
  --header: hsl(0 0% 65%);
  --lit-header: hsl(0 0% 100%);
  --speed: 2s;
  --ease: linear(
    0 0%,
    0.0036 9.62%,
    0.0185 16.66%,
    0.0489 23.03%,
    0.0962 28.86%,
    0.1705 34.93%,
    0.269 40.66%,
    0.3867 45.89%,
    0.5833 52.95%,
    0.683 57.05%,
    0.7829 62.14%,
    0.8621 67.46%,
    0.8991 70.68%,
    0.9299 74.03%,
    0.9545 77.52%,
    0.9735 81.21%,
    0.9865 85%,
    0.9949 89.15%,
    1 100%
  );
}

#particles {
  /* position:fixed; */
  top: 0;
  left: 0;
  width: 100%;
  z-index: -99; /* Send particles behind the content */
  pointer-events: none; /* Avoid interference with other elements */
}

body {
  background: #fbfbff;
}

section,
footer {
  z-index: 1;
  background: #fbfbff;
  position: relative;
}

.gradient-text {
  background: linear-gradient(to right, #f97316, #ed82f1) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
}

#service {
  /* background-image: url(/bg-bubble.png); */
  background-size: contain;
  background-position: center center;
}

.service-card {
  backdrop-filter: blur(7px) saturate(94%);
  -webkit-backdrop-filter: blur(7px) saturate(94%);
  background-color: rgba(78, 78, 78, 0);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
}

#contact {
  background-image: url(/contact/bg.png);
  background-position: center bottom;
  background-size: cover;
}

/* Hero.css */
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-tick li::before {
  content: "✅";
}

.blog-categories li:last-child a {
  border-bottom: none;
}

.nav-fix {
  position: fixed !important;
}

.notfound *,
.notfound *:after,
.notfound *:before {
  box-sizing: border-box;
  transform-style: preserve-3d;
}

/* ---------------------------------------------- */
.notfound {
  color-scheme: dark only;
  /* display: flex; */
  /* justify-content: center; */
  overflow: hidden;
}

.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  gap: 1rem;
  background: var(--bg);
  font-family: "Roboto", "SF Pro Text", "SF Pro Icons", "AOS Icons",
    "Helvetica Neue", Helvetica, Arial, sans-serif, system-ui;
  perspective: 1200px;
  width: 100%;
}

.not-found-container::before {
  --size: 45px;
  --line: color-mix(in lch, canvas, transparent 85%);
  content: "";
  height: 100vh;
  width: 100vw;
  position: fixed;
  background: linear-gradient(
        90deg,
        var(--line) 1px,
        transparent 1px var(--size)
      )
      50% 50% / var(--size) var(--size),
    linear-gradient(var(--line) 1px, transparent 1px var(--size)) 50% 50% /
      var(--size) var(--size);
  mask: linear-gradient(-35deg, transparent 30%, white);
  top: 0;
  transform-style: flat;
  pointer-events: none;
  z-index: -1;
}

@property --swing-x {
  initial-value: 0;
  inherits: false;
  syntax: "<integer>";
}
@property --swing-y {
  initial-value: 0;
  inherits: false;
  syntax: "<integer>";
}

.notfound h1 {
  animation: swing var(--speed) infinite alternate var(--ease);
  font-size: clamp(5rem, 40vmin, 20rem);
  font-family: "Open Sans", sans-serif;
  font-weight: 900;
  margin: 0;
  letter-spacing: 1rem;
  transform: translate3d(0, 0, 0vmin);
  --x: calc(50% + (var(--swing-x) * 0.5) * 1%);
  background: radial-gradient(var(--lit-header), var(--header) 45%) var(--x)
    100%/200% 200%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  z-index: 4;
}

.notfound h1 span {
  animation: swing var(--speed) infinite alternate var(--ease);
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  color: var(--shadow);
  filter: blur(1.5vmin);
  transform: scale(1.05) translate3d(0, 12%, -10vmin)
    translate(
      calc((var(--swing-x) * 0.05) * 1%),
      calc((var(--swing-y) * -0.025) * 1%)
    );
}

.cloak {
  animation: swing var(--speed) infinite alternate-reverse var(--ease);
  height: 100%;
  width: 100%;
  transform-origin: 50% 25%;
  transform: rotate(calc(var(--swing-x) * -0.25deg));
  background: radial-gradient(
    40% 40% at 50% calc(42% + (var(--swing-y) * 0.01%)),
    transparent,
    hsl(0 0% 2% / 0.94) 38vmax
  );
}

.cloak__wrapper {
  position: fixed;
  inset: 0;
  overflow: hidden;
  pointer-events: none;
}
.cloak__container {
  height: 250vmax;
  width: 250vmax;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.info {
  color: color-mix(in lch, canvasText, transparent 75%);
  text-align: center;
  line-height: 1.5;
  width: 44ch;
  max-width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: color 0.25s var(--ease);
}

.info:is(:hover, :focus-within) {
  color: canvasText;
}

.info p {
  --x: calc(50% + (var(--swing-x) * 0.5) * 1%);
  animation: swing var(--speed) infinite alternate-reverse var(--ease);
  background: radial-gradient(
    50% 250% at var(--x) -50%,
    color-mix(in lch, canvasText, transparent 25%),
    color-mix(in lch, canvasText, transparent 75%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.notfound p::selection {
  background: hotpink;
  color: canvas;
}

.follow {
  border-radius: 6px;
  border: 1px solid currentColor;
  padding: 0.75rem 4rem;
  text-decoration: none;
  color: currentColor;
  align-self: center;
  outline-color: currentColor;
}

@keyframes swing {
  0% {
    --swing-x: -100;
    --swing-y: -100;
  }
  50% {
    --swing-y: 0;
  }
  100% {
    --swing-y: -100;
    --swing-x: 100;
  }
}

/* Theming */
.bear-link {
  color: canvas;
  position: fixed;
  top: 1rem;
  left: 1rem;
  width: 48px;
  aspect-ratio: 1;
  display: grid;
  place-items: center;
  opacity: 0.8;
}

:where(.x-link, .bear-link):is(:hover, :focus-visible) {
  opacity: 1;
}
.bear-link svg {
  width: 75%;
}

/* =============================================== /*
  /*                                                 /* 
  /*                     Messages                    /*
  /*                                                 /*
  /* =============================================== */

#messages-review {
  position: relative;
  padding: 0;
  max-width: 900px;
  list-style: none;
  z-index: 2;
}

#messages-review li {
  float: left;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px dashed #c8c7b4;
}

#messages-review li:nth-child(2n) .infos {
  float: right;
  margin-left: 20px;
}

#messages-review li:nth-child(2n) .infos a {
  -webkit-box-shadow: 1px 1px 0 1px rgba(90, 81, 15, 0.2);
  -moz-box-shadow: 1px 1px 0 1px rgba(90, 81, 15, 0.2);
  box-shadow: 1px 1px 0 1px rgba(90, 81, 15, 0.2);
  left: inherit;
}

#messages-review li:nth-child(2n) .content-review {
  border-width: 1px 3px 4px 4px;
}

#messages-review li:nth-child(2n) .content-review:after,
#messages-review li:nth-child(2n) .content-review:before {
  right: -9px;
  left: inherit;
  -webkit-transform: rotate(41deg);
  -moz-transform: rotate(41deg);
  -o-transform: rotate(41deg);
  transform: rotate(41deg);
}

#messages-review li:nth-child(2n) .facebook {
  right: -10px;
}

#messages-review li:nth-child(2n) .twitter {
  right: -5%;
}

#messages-review li:nth-child(2n) .github {
  right: -1%;
}

#messages-review .infos {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  float: left;
  padding: 3px;
  width: 130px;
  height: 130px;
  border: 5px solid #c8c7b4;
}

#messages-review .infos a {
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  -ms-border-radius: 17px;
  -o-border-radius: 17px;
  border-radius: 17px;
  -webkit-box-shadow: -1px 1px 0 1px rgba(90, 81, 15, 0.2);
  -moz-box-shadow: -1px 1px 0 1px rgba(90, 81, 15, 0.2);
  box-shadow: -1px 1px 0 1px rgba(90, 81, 15, 0.2);
  position: absolute;
  width: 20px;
  height: 22px;
  background-color: #d5d2a8;
  color: #fff;
  text-decoration: none;
  text-indent: -10000px;
  font-size: 11px;
}

#messages-review .infos a:hover {
  padding: 0 10px 0 18px;
  width: auto;
  border: none;
  text-indent: 0;
  opacity: 0.95;
}

#messages-review img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  width: 100%;
}

/* =============================================== /*
    /*                                                 /* 
    /*      + SVG sprite (social media icons ).        /*
    /*      + You can use PNG if your navigator don't  /*
    /*        support SVG format.                      /*
    /*                                                 /*
    /* =============================================== */
.content-review {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  position: relative;
  float: left;
  margin-left: 20px;
  padding: 15px 25px;
  max-width:700px;
  border-width: 1px 4px 4px 3px;
  border-style: solid;
  border-color: #c8c7b4;
  background-color: #ece5bd;

  /* ==================================================================== /*
    /*                                                                      /* 
    /*   + Css Patterns from : http://lea.verou.me/css3patterns/            /* 
    /*   + Arbaoui mehdi : Fix a gradient color bug in OPERA,FIREFOX        /*
    /*     and SAFARI (only new versions)                                   /*
    /*                                                                      /*
    /* ==================================================================== */

  background-image: -linear-gradient(
      0deg,
      transparent 18px,
      #dbada9 0,
      #dbada9 20px,
      transparent 19px
    ),
    -linear-gradient(#e1dab0 0.1em, transparent 0.1em);
  background-image: -webkit-linear-gradient(
      0deg,
      transparent 11px,
      #dbada9 0,
      #dbada9 14px,
      transparent 12px
    ),
    -webkit-linear-gradient(#e1dab0 0.1em, transparent 0.1em);
  background-image: -moz-linear-gradient(
      0deg,
      transparent 11px,
      #dbada9 0,
      #dbada9 13px,
      transparent 12px
    ),
    -moz-linear-gradient(#e1dab0 0.1em, transparent 0.1em);
  background-size: 100% 10px;
  color: #333;
}

/* ====================================================================================== /*
    /*                                                                                        /* 
    /*   + Talk bubble with shadow                                                            /* 
    /*     From http://forrst.com/posts/How_to_Make_a_Pure_CSS_Speech_Bubble_With_a_Drop-PU1  /*
    /*   + Arbaoui mehdi: Change position (left and right) of arrow                        /*
    /*                                                                                        /*
    /* ====================================================================================== */

.content-review:after,
.content-review:before {
  border-bottom: 23px solid transparent;
  border-right: 20px solid #ece5bd;
  top: 49px;
  content: "";
  position: absolute;
  left: -9px;
  -webkit-transform: rotate(220deg);
  -moz-transform: rotate(220deg);
  -o-transform: rotate(220deg);
  transform: rotate(220deg);
}

.content-review h3 {
  font: 23px "Satisfy";
  margin: 0;
  padding: 0;
  font-weight: normal;
}

.content-review p {
  margin: 10px 0 0 0;
  font-size: 15px;
  line-height: 1.7em;
}

.content-review p a {
  text-decoration: none;
  background-color: #f7f3c5;
  border-bottom: solid 1px #cfcebe;
  color: #834202;
  padding: 1px 4px;
}

.content-review p a:hover {
  background-color: #f5ec82;
}

.content-review b {
  display: block;
  color: #75280c;
  font: italic normal 17px Georgia;
}

.content-review:before {
  top: 48px;
  left: -11px;
  border-right: 19px solid rgba(0, 0, 0, 0.1);
}
